const leadsRoutes: Menu.MenuOptions[] = [
	{
		path: "/leads-plateform",
		name: "leads-plateform",
		meta: {
			icon: "leads-plateform",
			title: "线索平台",
			enTitle: "Leads Plateform",
			isLink: "",
			isHide: false,
			isFull: false,
			isAffix: false,
			isKeepAlive: false
		},
		children: [
			{
				path: "/leads-plateform/dashboard",
				name: "leads-dashboard",
				component: "/leads-plateform/dashboard/index",
				meta: {
					icon: "dashboard",
					title: "Dashboard",
					enTitle: "",
					isLink: "",
					isHide: false,
					isFull: false,
					isAffix: false,
					isKeepAlive: false
				}
			},
			{
				path: "/leads-plateform/installers",
				name: "leads-installers",
				meta: {
					icon: "installer",
					title: "安装商",
					enTitle: "",
					isLink: "",
					isHide: false,
					isFull: false,
					isAffix: false,
					isKeepAlive: false
				},
				redirect: "/leads-plateform/installers/list",
				children: [
					{
						path: "/leads-plateform/installers/list",
						name: "leads-installers-list",
						meta: {
							icon: "",
							title: "列表",
							enTitle: "",
							isLink: "",
							isHide: true,
							isFull: false,
							isAffix: false,
							isKeepAlive: true
						},
						component: "/leads-plateform/installers/index"
					},
					{
						path: "/leads-plateform/installers/detail",
						name: "leads-installers-detail",
						meta: {
							icon: "",
							title: "详情",
							enTitle: "",
							isLink: "",
							isHide: true,
							isFull: false,
							isAffix: false,
							isKeepAlive: false
						},
						component: "/installer-manage/user/company/detail/index"
					}
				]
			},
			{
				path: "/leads-plateform/leads-manage",
				name: "leads-manage",
				meta: {
					icon: "leads-manage",
					title: "商机管理",
					enTitle: "",
					isLink: "",
					isHide: false,
					isFull: false,
					isAffix: false,
					isKeepAlive: true
				},
				children: [
					{
						path: "/leads-plateform/leads-manage/leads-list",
						name: "leads-list",
						component: "/leads-plateform/leads-manage/leads-list",
						meta: {
							icon: "",
							title: "商机列表",
							enTitle: "",
							isLink: "",
							isHide: false,
							isFull: false,
							isAffix: false,
							isKeepAlive: true
						}
					},
					{
						path: "/leads-plateform/leads-manage/leads-detail",
						name: "leads-detail",
						component: "/leads-plateform/leads-manage/leads-detail",
						meta: {
							icon: "Menu",
							title: "线索详情",
							enTitle: "",
							isLink: "",
							isHide: true,
							isFull: false,
							isAffix: false,
							isKeepAlive: false
						}
					}
				]
			},
			{
				path: "/leads-plateform/operation-manage",
				name: "operation-manage",
				meta: {
					icon: "operation-manage",
					title: "运营管理",
					enTitle: "",
					isLink: "",
					isHide: false,
					isFull: false,
					isAffix: false,
					isKeepAlive: false
				},
				children: [
					{
						path: "/leads-plateform/operation-manage/leads-config",
						name: "leads-config",
						component: "/leads-plateform/operation-manage/leads-config/index",
						meta: {
							icon: "Menu",
							title: "商机配置",
							enTitle: "",
							isLink: "",
							isHide: false,
							isFull: false,
							isAffix: false,
							isKeepAlive: false
						}
					},
					{
						path: "/leads-plateform/operation-manage/email-template",
						name: "leads-email-template",
						redirect: "/leads-plateform/operation-manage/email-template/list",
						meta: {
							icon: "Menu",
							title: "邮件模板",
							enTitle: "",
							isLink: "",
							isHide: false,
							isFull: false,
							isAffix: false,
							isKeepAlive: false
						},
						children: [
							{
								path: "/leads-plateform/operation-manage/email-template/list",
								name: "email-template-list",
								component: "/leads-plateform/operation-manage/email-template/list",
								meta: {
									icon: "Menu",
									title: "模板列表",
									enTitle: "",
									isLink: "",
									isHide: false,
									isFull: false,
									isAffix: false,
									isKeepAlive: true
								}
							},
							{
								path: "/leads-plateform/operation-manage/email-template/detail",
								name: "email-template-detail",
								component: "/leads-plateform/operation-manage/email-template/detail",
								meta: {
									icon: "Menu",
									title: "模板详情",
									enTitle: "",
									isLink: "",
									isHide: true,
									isFull: false,
									isAffix: false,
									isKeepAlive: false
								}
							}
						]
					},
					{
						path: "/leads-plateform/operation-manage/operation-log",
						name: "leads-log-operation-log",
						component: "/leads-plateform/operation-manage/log/operation-log",
						meta: {
							icon: "",
							title: "操作日志",
							enTitle: "",
							isLink: "",
							isHide: false,
							isFull: false,
							isAffix: false,
							isKeepAlive: true
						}
					},
					{
						path: "/leads-plateform/operation-manage/operation-log/detail",
						name: "leads-log-operation-log-detail",
						component: "/leads-plateform/operation-manage/log/operation-log-detail",
						meta: {
							icon: "",
							title: "操作日志详情",
							enTitle: "",
							isLink: "",
							isHide: true,
							isFull: false,
							isAffix: false,
							isKeepAlive: false
						}
					},
					{
						path: "/leads-plateform/operation-manage/email-log",
						name: "leads-log-email-log",
						component: "/leads-plateform/operation-manage/log/email-log",
						meta: {
							icon: "",
							title: "邮件发送日志",
							enTitle: "",
							isLink: "",
							isHide: false,
							isFull: false,
							isAffix: false,
							isKeepAlive: true
						}
					}
				]
			}
		]
	}
];

export default leadsRoutes;
