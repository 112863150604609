import http from "@/api";
import { PORT1 } from "@/api/config/servicePort";
import { ResultData } from "../interface";

/**
 * @name 文件上传
 */
export const uploadFile = (params: FormData, option: Record<string, any> = {}) => {
	return http.post<ResultData>(PORT1 + `/uploadFile`, params, { timeout: 180000, ...option });
};

/**
 * 查询国家列表
 * @param params
 * @returns
 */
export const queryCountries = (params = {}, options = {}) => {
	return http.get<ResultData<Record<string, any>[]>>(PORT1 + `/countryByVersion`, params, options);
};

// /**
//  * 根据国家查询州
//  * @param params
//  * @param options
//  * @returns
//  */
// export const queryStateList = (params = {}, options = {}) => {
// 	return http.get<ResultData<Record<string, any>[]>>(PORT1 + `/`, params, options);
// };

/**
 * 查询网站版本列表
 * @param params
 * @returns
 */
export const queryWebsiteVersions = (params = {}, options = {}) => {
	return http.get<ResultData<Record<string, any>[]>>(PORT1 + `/versionList`, params, options);
};

// 图片下载
export const downloadFile = async (params = {}, options = {}) => {
	return http.download(PORT1 + `/download`, params, options);
};

// 添加搜索条件
export const addSearchCondition = async (params = {}, options = {}) => {
	return http.post(PORT1 + `/searchConditionCreate`, params, { context: true, ...options });
};

// 查询搜索条件
export const querySearchCondition = async (params = {}, options = {}) => {
	return http.post(PORT1 + `/searchConditionDetail`, params, options);
};

// 查询国家列表，数据源来自云平台
export const queryCountriesFromCloudPlatform = async (params = {}, options = {}) => {
	return http.get(PORT1 + `/tCountryList`, params, options);
};

// 查询州列表，数据源来自云平台
export const queryStateListFromCloudPlatform = async (params = {}, options = {}) => {
	return http.get(PORT1 + `/tStateList`, params, options);
};
