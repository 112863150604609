export default {
	versionManage: "Version Management",
	versionCode: "Version Code",
	publishTime: "Release Time",
	updateTime: "Update Time",
	publishVersion: "Release Version",
	NO: "NO.",
	courseName: "Course Name",
	courseObjective: "Course Objective",
	trainMember: "Train Member",
	video: "Video",
	createTime: "Create Time",
	type: "Type",
	status: "Status",
	useStatus: "Use Status",
	operation: "Operation",
	courseType: "Course Type",
	unitExam: "Unit Exam",
	comprehensiveExam: "comprehensive Exam",
	courseIntroduction: "Course Introduction",
	courseDescription: "Course Description",
	courseVideo: "Course Video",
	courseExam: "Course Exam",
	examName: "Exam Name",
	examList: "Examination List",
	websiteVersion: "Website Version",
	belongToCountry: "Belongs To Country",
	country: "Country",
	state: "State",
	city: "City",
	zipCode: "Zip Code",
	belongsToCourse: "Belongs To Course",
	videoTitle: "Video Title",
	videoDuration: "Video Duration",
	index: "Index",
	draft: "Draft",
	released: "Released",
	unreleased: "Unreleased",
	effective: "Effective",
	notEffective: "uneffective",
	offline: "Offline",
	edit: "Edit",
	cancel: "Cancel",
	save: "Save",
	uploadVideo: "Upload Video",
	changeVideo: "Change Video",
	addVideo: "Add Video",
	content: "Content",
	certificationCourse: "Certification Course",
	installer: "Installer",
	sales: "Sales",
	designer: "Designer",
	needExamination: "Need exam",
	needNotification: "Need notice",
	pending: "Pending...",
	createCustomCourse: "Create Custom Course",
	belongsToVersion: "Belongs To Version",

	lessonType: "Lesson Type",
	lessonName: "Lesson Name",
	lessonDescription: "Lesson Description",
	lessonExamination: "Lesson Examination",
	lessonVideo: "Lesson Video",
	randomQuantity: "Number of randomized questions",

	examination: {
		name: "Exam Name",
		number: "Exam Number",
		copy: "Copy",
		addPicture: "Add Picture",
		type: "Exam Type",
		answerOption: "Answer Option",
		addOption: "Add Option",
		correctAnswer: "Correct Answer",
		answerAnalysis: "Answer Analysis",
		radio: "Radio",
		checkbox: "Checkbox",
		addExamination: "Add Examination",
		used: "Used",
		unUsed: "Unused",
		onlineStatus: "Online Status",
		online: "Online",
		offline: "Offline",
		doOnline: "Online",
		doOffline: "Offline"
	},

	version: {
		number: "Number",
		addCourse: "Add Course",
		addLesson: "Add Lesson",
		createVersion: "Create Version",
		createVersionTips:
			"The version number must retain one decimal place, and the new version number must be greater than the existing version number",
		language: "Course Language",
		english: "English",
		spanish: "Spanish",
		addVersionLanguage: "Add new language courses",
		createCourse: "Create Course",
		lessonName: "Lesson Name"
	},

	document: {
		title: "Title",
		subtitle: "Subtitle",
		docTitle: "Document Title",
		description: "Document description",
		uploadDocument: "Upload document",
		seoUrl: "SEO URL",

		lang: "Language",
		selectLanguage: "Select language",
		category: "Category",
		role: "Role"
	},

	media: {
		cover: "Cover",
		title: "Title",
		videoTitle: "Video Title",
		summary: "Summary",
		tips: {
			release: "Are you sure you want to publish this media?",
			cancelRelease: "Are you sure you want to unpublish this media?"
		}
	},

	tips: {
		removeOption: "Whether to remove this option?",
		publishVersion: "This version {version} will be released, do you want to continue?",
		cancelPublishVersion: "This version {version} will be cancel released, do you want to continue?",
		countLessThanTwo: "The number of courses is less than 2 and cannot be sorted",
		releaseDocument: "Are you sure you want to publish this document?",
		cancelReleaseDocument: "Are you sure you want to cancel publish this document?",
		releaseMedia: "Are you sure you want to publish this media?",
		uploadVideoDescription1: "Upload video up to 500M",
		uploadVideoDescription2:
			"After the video is uploaded, the system takes 5-30 minutes to process the data and the processing must be complete before the video can be used",
		uploadVideoDescription3:
			"If you are replacing the original video, you will not be able to view the new video until the replacement takes effect.",
		uploadingTips:
			"The system takes 5-30 minutes to process the data and the processing must be complete before the video can be used",
		confirmCopy: "Confirmation of copying the option?",
		confirmModify: "Confirmation of modification of online status?",
		onlineVideo: "Is it confirmed that the video is online?",
		offlineVideo: "Is it confirmed that the video is offline?",
		onlineExamination: "Is it confirmed that the examination is online?",
		offlineExamination: "Is it confirmed that the examination is offline?"
	},

	rules: {
		videoTitle: "Please enter video title",
		chooseWebsiteVersion: "Please choose website version",
		uploadVideo: "Please upload video",
		videoTitleLengthLimit: "Video name length is limited between 1-200",
		enterExamDescription: "Please enter examination description",
		chooseExamType: "Please enter examination type",
		enterAnswerOption: "Please enter answer option",
		chooseCorrectAnswer: "Please choose correct answer",
		enterCompleteAnswerOption: "Please enter complete answer option",
		examinationVersion: "Please enter examination version",
		chooseCourseType: "Please choose course type",
		chooseLessonType: "Please choose lesson type",
		chooseCourseCatogory: "Please choose course category",
		enterCourseName: "Please enter course name",
		enterLessonName: "Please enter lesson name",
		enterCourseIntroduction: "Please enter course introduction",
		enterCourseDescription: "Please enter course description",
		chooseLessonVideo: "Please choose lesson video",
		chooseCourseExam: "Please choose course examination",
		chooseLessonExam: "Please choose lesson examination",
		enterRandomQuantity: "Please enter a random quantity",
		questionCountTips:
			"The number of questions in the question list must be greater than or equal to the number of randomized questions.",
		enterDocumentTitle: "Please enter document title",
		enterDocumentTitleLimitTips: "Maximum input is no more than {length} characters",
		documentDescriptionLimitTips: "Maximum input is no more than {length} characters",
		uploadDocument: "Please upload document",
		enterVideoTitle: "Please enter video title",
		chooseEffectiveType: "Please choose effective type",
		chooseEffectiveTime: "Please choose effective time",
		enterNoticeTitle: "Please enter notice title",
		enterfaqTitle: "Please enter faq title",
		enterNoticeContent: "Please enter notice content",
		enterFaqContent: "Please enter faq content",
		enterSeoUrl: "Please enter seo url",
		trainMember: "Please choose train member",
		courseType: "Please select course type",
		trainObject: "Please select training object",
		courseLanguage: "Please select course language",
		courseCover: "Please upload course cover image",
		chooseVersion: "Please select version",
		chooseLanguage: "Please select language",
		chooseCategory: "Please select category",
		chooseUserRole: "Please select Role",
		examCountTips: "The number of questions must be a positive integer"
	},

	faq: {
		releaseTips: "Are you sure you want to publish this FAQ?",
		unReleaseTips: "Are you sure you want to unpublish this FAQ?"
	}
};
